
export default {
  name: 'EnerDownloadFloatingButton',
  data () {
    return {
      isOpen: false
    }
  },
  props: {
    buttons: {
      required: false,
      type: Array,
      default: () => ['global', 'page']
    }
  },
  methods: {
    toggleMenu () {
      this.isOpen = !this.isOpen
    },
    handleClickOutside () {
      if (this.isOpen === true) {
        this.isOpen = false
      }
    }
  }
}
